import React from 'react'
import styled from 'styled-components'

const Idea = () => {
  return (
    <IdeaSection>
      <IdeaText>Your time and effort is valuable.
                Use it to serve your customers and 
                let me help you with the details.</IdeaText>
    </IdeaSection>
  )
}

export default Idea

const IdeaSection = styled.section`
    height: 380px;
    background-color: #2A2C2B;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
`

const IdeaText = styled.p`
    text-align: center;
    font-size: 1.6em;
    font-weight: 300;
    line-height: 1.4;
    color: #ffffff;
    width: 40%;

    @media screen and (max-width: 1020px){
      width: 60%;
  }

  @media screen and (max-width: 768px){
      width: 80%;
  }
`