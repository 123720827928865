import * as React from "react"
import Layout from "../components/Layout"

import AppSeo from "../components/AppSeo"
import ServiceHero from "../components/ServiceHero"
import GetResults from "../components/GetResults"
import FocusBig from "../components/FocusBig"
import Idea from "../components/Idea"
import WhyInvest from "../components/WhyInvest"
import BusinessCTA from "../components/BusinessCTA"
import WhatMakesMeSpecial from "../components/WhatMakesMeSpecial"
import WhatICanBring from "../components/WhatICanBring"
import Note from "../components/Note"
import Services from "../components/Services"
import WhyChooseMe from "../components/WhyChooseMe"
import DevProcess from "../components/DevProcess"
import Pricing from "../components/Pricing"
import Contact from "../components/Contact"
import Mail from "../components/Mail"




const services = () => (
  <Layout>
    <AppSeo title="Services" />
    <ServiceHero></ServiceHero>
    <GetResults></GetResults>
    <FocusBig></FocusBig>
    <Idea></Idea>
    <WhyInvest></WhyInvest>
    <BusinessCTA></BusinessCTA>
    <WhatMakesMeSpecial></WhatMakesMeSpecial>
    <WhatICanBring></WhatICanBring>
    <Note></Note>
    <Services></Services>
    <WhyChooseMe></WhyChooseMe>
    <DevProcess></DevProcess>
    <Pricing></Pricing>
    <Contact></Contact>
    <Mail></Mail>
  </Layout>
)

export default services
