import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const ServiceHero = () => {
  return (
    <ServiceHeroContainer>
      <ServiceHeroLayout>
        <ServiceHeroTextBox>
            <ServiceHeroTitle>Your site is like a home,</ServiceHeroTitle>
            <ServiceHeroSubtitle>let’s make sure your customers enjoy <br></br> their stay</ServiceHeroSubtitle>
        </ServiceHeroTextBox>
        <ServiceHeroImage>
          <StaticImage src="../images/working-on-a-project.png" alt="Working on a project" placeholder="#fff" layout="constrained" height={500}/>
        </ServiceHeroImage>
      </ServiceHeroLayout>
    </ServiceHeroContainer>
  )
}

export default ServiceHero


const ServiceHeroContainer = styled.div`
    margin: 0 9.375rem;
    padding: 0 calc((100vw - 1440px) / 2);
    height: auto;
    color: #2A2C2B;
    text-transform: uppercase;


    @media screen and (max-width: 1020px){
      margin: 0 2rem;
      padding: 0;
    }

    @media screen and (max-width: 768px){
      margin: 0 0rem;
      padding: 0;
    }
`

const ServiceHeroLayout = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 768px){

    }
`

const ServiceHeroTextBox = styled.div`
    background-color: white;
    margin-right: -20%;
    padding: 2em 2em 2em 0;
    //position: absolute;
    align-self: center;

    @media screen and (max-width: 1020px){
      margin: 0;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }



`

const ServiceHeroTitle = styled.h1`
    font-size: 4em;
    line-height: .9em;
    font-weight: 800;


`

const ServiceHeroSubtitle = styled.h2`
    font-size: 1.2em;
    margin-top: 1em;
    font-weight: 600;
    color: #6B767E;
`

const ServiceHeroImage = styled.div`
    z-index: -2;

`
