import React from 'react'
import styled from 'styled-components'
import { Button } from "./Button"

const BusinessCTA = () => {
  return (
    <BusinessCTASection>
      <BusinessCTATitle>Want to make improvements?</BusinessCTATitle>
      <BusinessCTAText>I can steer your business on the right track. <br></br>
      Let me show you how.</BusinessCTAText>
      <Button Primary="false" fweight="true" to="/projectassesment">Get Started</Button>
      
    </BusinessCTASection>
  )
}

export default BusinessCTA

const BusinessCTASection = styled.section`
    background-color: #2A2C2B;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    padding-bottom: 4em;
    padding-top: 2em;
    text-align: center;
`

const BusinessCTATitle = styled.h2`
    color: #ffffff;
    font-size: 2em;
    text-transform: uppercase;
    margin: 1em 0 .6em 0;
`

const BusinessCTAText = styled.p`
    color: #ffffff;
    width: 50%;
    margin-bottom: 1em;

    @media screen and (max-width: 1020px){
      width: 70%;
    }

    @media screen and (max-width: 768px){
      width: 80%
    }
`