import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "./Button"

const Pricing = () => {
  return (
    <PricingSection>
      <PricingContainer>
        <PricingHeading>Pricing</PricingHeading>
        <PricingText>I recommend my premium all-in-one package that establishes your company for online success.</PricingText>
        <PricingList>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Full_Custom_Website.svg" alt="Line art of a website" placeholder="#fff" layout="constrained" height={80}/></PricingImage>
            <PricingTitle>FULL CUSTOM WEBSITE</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Search_Engine_Optimization.svg" alt="Line art of a magnifying glass" placeholder="#fff" layout="constrained" height={100}/></PricingImage>
            <PricingTitle>SEARCH ENGINE OPTIMIZATION</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Design_And_Illustration.svg" alt="Line art of a pen and a ruler" placeholder="#fff" layout="constrained" height={90}/></PricingImage>
            <PricingTitle>DESIGN & ILLUSTRATION</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Brand_Identity.svg" alt="Line art of a character wondering about an identity of a company" placeholder="#fff" layout="constrained" height={80}/></PricingImage>
            <PricingTitle>BRAND IDENTITY</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Content_Production.svg" alt="Line art of different media in speech bubbles" placeholder="#fff" layout="constrained" height={80}/></PricingImage>
            <PricingTitle>CONTENT PRODUCTION</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Copywriting.svg" alt="Line art of some documents and sticky notes" placeholder="#fff" layout="constrained" height={80}/></PricingImage>
            <PricingTitle>COPYWRITING</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Social_Media_Setup.svg" alt="Line art of characters wearing social media logos" placeholder="#fff" layout="constrained" height={80}/></PricingImage>
            <PricingTitle>SOCIAL MEDIA SETUP</PricingTitle>
          </PricingBox>
          <PricingBox>
            <PricingImage><StaticImage src="../images/Analytics_Setup.svg" alt="Line art of a chart trending higher" placeholder="#fff" layout="constrained" height={80}/></PricingImage>
            <PricingTitle>ANALYTICS SETUP</PricingTitle>
          </PricingBox>
        </PricingList>
        <PricingCTA>
          <PricingPrice>STARTING AT 9,500 USD</PricingPrice>
          <Button to="/projectassesment" Primary="false" fweight="true">To Project Assesment</Button>
        </PricingCTA>
      </PricingContainer>
    </PricingSection>
  )
}

export default Pricing

const PricingSection = styled.section`

`

const PricingContainer = styled.div`
    margin: 0 9.375rem 6rem 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const PricingHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const PricingList = styled.div`
    margin: 4em 5em 2em 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 3em;

    @media screen and (max-width: 1250px){
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2em;
      grid-row-gap: 2em;
    }

    @media screen and (max-width: 1020px){

    }

    @media screen and (max-width: 768px){
      grid-template-columns: 1fr;
      margin: 2em 0;
    }
`

const PricingBox = styled.div`
    background-color: #ECECEC;
    padding: 1.4em 2em 3em 2em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
    "thumb"
    "title";
    grid-column-gap: 2em;
    grid-row-gap: 1em;
`

const PricingImage = styled.div`
    grid-area: thumb;
    justify-content: center;
    align-self: center;
`

const PricingTitle = styled.h3`
    grid-area: title;
    text-transform: none;
    margin-bottom: .4em;
    font-weight: 500;
    font-size: 1.2em;
    color: #374140;
`

const PricingText = styled.p`
    color: #374140;
`

const PricingCTA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PricingPrice = styled.div`
    margin-top: 2em;
    color: #2A2C2B;
    font-size: 1.6em;
    font-weight: 500;
    margin-bottom: 2em;
`