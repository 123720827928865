import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const WhatICanBring = () => {
  return (
    <WhatICanBringSection>
      <WhatICanBringContainer>
        <WhatICanBringHeading>WHAT I CAN BRING</WhatICanBringHeading>
        <WhatICanBringList>
          <WhatICanBringBox>
            <WhatICanBringImage><StaticImage src="../images/Design_Skills.svg" alt="Cartoony graphic of a pencil drawing a line" placeholder="#fff" layout="fixed" height={80}/></WhatICanBringImage>
            <WhatICanBringTitle>DESIGN SKILLS</WhatICanBringTitle>
            <WhatICanBringText>I’m able to design most suitable layouts and build a seamless experience for your customers.</WhatICanBringText>
          </WhatICanBringBox>
          <WhatICanBringBox>
            <WhatICanBringImage><StaticImage src="../images/Business_Knowledge.svg" alt="Cartoony graphic of a small shop" placeholder="#fff" layout="fixed" height={100}/></WhatICanBringImage>
            <WhatICanBringTitle>BUSINESS KNOWLEDGE</WhatICanBringTitle>
            <WhatICanBringText>As a free consultant I can leverage past experiences and solutions to see what could work in your situation.</WhatICanBringText>
          </WhatICanBringBox>
          <WhatICanBringBox>
            <WhatICanBringImage><StaticImage src="../images/Website_Consulting.svg" alt="Cartoony graphic of a consulting session" placeholder="#fff" layout="fixed" height={90}/></WhatICanBringImage>
            <WhatICanBringTitle>WEBSITE CONSULTING</WhatICanBringTitle>
            <WhatICanBringText>No matter what type of solution you need, I can guide the project in the right direction and educate you to make the best choices.</WhatICanBringText>
          </WhatICanBringBox>
          <WhatICanBringBox>
            <WhatICanBringImage><StaticImage src="../images/Seo_And_Marketing.svg" alt="Cartoony graphic of a large screen handheld device" placeholder="#fff" layout="fixed" height={80}/></WhatICanBringImage>
            <WhatICanBringTitle>SEO AND MARKETING</WhatICanBringTitle>
            <WhatICanBringText>I can pinpoint problematic areas in your current traffic acquisition to fix or plan a strategy if you’re just starting out the business.</WhatICanBringText>
          </WhatICanBringBox>
          <WhatICanBringBox>
            <WhatICanBringImage><StaticImage src="../images/Content_Planning_And_Production.svg" alt="Cartoony graphic of a camera and some papers" placeholder="#fff" layout="fixed" height={80}/></WhatICanBringImage>
            <WhatICanBringTitle>CONTENT PLANNING AND PRODUCTION</WhatICanBringTitle>
            <WhatICanBringText>I can plan out your content and produce what is needed, including text, graphics, photos and video.</WhatICanBringText>
          </WhatICanBringBox>
        </WhatICanBringList>
      </WhatICanBringContainer>
    </WhatICanBringSection>
  )
}

export default WhatICanBring

const WhatICanBringSection = styled.section`

`

const WhatICanBringContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const WhatICanBringHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const WhatICanBringList = styled.div`
    margin: 4em 5em;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 3em;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;

    }
`

const WhatICanBringBox = styled.div`
    background-color: #ECECEC;
    padding: 1em 2em 3em 2em;
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-template-areas:
    "thumb title"
    "thumb desc";
    grid-column-gap: 2em;

    @media screen and (max-width: 1020px){
    margin: 0 2rem;
    grid-template-columns: 1fr;
    grid-template-areas:
    "thumb"
    "thumb"
    "title"
    "desc";
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const WhatICanBringImage = styled.div`
    grid-area: thumb;
    justify-content: center;
    align-self: center;

    @media screen and (max-width: 1020px){
    margin: 1rem 0 2rem 0;
    }
`

const WhatICanBringTitle = styled.h3`
    grid-area: title;
    margin-bottom: .4em;
    font-weight: 500;
    font-size: 1.6em;
    color: #374140;
`

const WhatICanBringText = styled.p`
    color: #374140;
    grid-area: desc;
`