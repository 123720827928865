import React from 'react'
import styled from 'styled-components'

function FocusBig() {
  return (
    <FocusBigSection>
      <FocusBigContainer>
        <FocusBigInfo>
          <FocusBigHeader>Focus on the big picture and reaching goals</FocusBigHeader>
          <FocusBigText>There are many ways to run a profitable business. Marketing and website management can be a time sink. Is it really necessary to try do it all by yourself?</FocusBigText>
          <FocusBigText>Perhaps it is time to admit, that you need an expert to tie it all together.</FocusBigText>
        </FocusBigInfo>
        <FocusBigImage></FocusBigImage>
      </FocusBigContainer>
    </FocusBigSection>
  )
}

export default FocusBig

const FocusBigSection = styled.section`
    color: #2A2C2B;
    margin-top: 2em;
`

const FocusBigContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    display: flex;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const FocusBigInfo = styled.div`
    margin-left: 5em;

    @media screen and (max-width: 768px){
      margin-left: 0;
    }
`

const FocusBigImage = styled.div`
    margin-left: 2em;
    flex: none;
    background-color: #87DABF;
    width: 200px;
    height: 200px;

    @media screen and (max-width: 1020px){
      width: 100px;
      height: 290px;
    }

    @media screen and (max-width: 768px){
      width: 50px;
      height: 450px;
    }
`

const FocusBigHeader = styled.h2`
    margin-top: .2em;
    margin-bottom: .6em;
    font-size: 2em;
`

const FocusBigText = styled.p`
    padding: .6rem 0;
    font-size: 1.2rem;
`