import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const WhyInvest = () => {
  return (
    <WhyInvestSection>
      <WhyInvestContainer>
        <WhyInvestImage><StaticImage src="../images/thinking.png" alt="Me thinking of a solution" placeholder="#fff" layout="fullWidth"/></WhyInvestImage>
        <WhyInvestInfo>
          <WhyInvestHeader>WHY INVEST IN A WEBSITE</WhyInvestHeader>
          <WhyInvestText>Getting actual results is a hard task that not everyone is able to pull off. Depending on the strategy, it will either cost money, time or both!</WhyInvestText>
          <WhyInvestText>Without a planned strategy all the efforts seem disconnected and futile. Website should tie all of your marketing and sales into a one place, that is your company's home on the web.</WhyInvestText>
          <WhyInvestText>With that being said, there are great benefits of owning a website. <br></br>
            Here’s my top reasons why:
          </WhyInvestText>
          <WhyInvestList>
            <WhyInvestListItem>Reach more people online</WhyInvestListItem>
            <WhyInvestListItem>Market products and services</WhyInvestListItem>
            <WhyInvestListItem>Receive more leads</WhyInvestListItem>
            <WhyInvestListItem>Increase sales</WhyInvestListItem>
            <WhyInvestListItem>Increase the value of your brand</WhyInvestListItem>
            <WhyInvestListItem>Have direct and independent platform to reach customers</WhyInvestListItem>
            <WhyInvestListItem>Share information about the company and latest developments</WhyInvestListItem>
          </WhyInvestList>
        </WhyInvestInfo>
      </WhyInvestContainer>
    </WhyInvestSection>
  )
}

export default WhyInvest


const WhyInvestSection = styled.section`
    color: #2A2C2B;
`

const WhyInvestContainer = styled.div`
    margin: 0 9.375rem;
    padding: 0 calc((100vw - 1440px) / 2);

    @media screen and (max-width: 1020px){
      margin: 0;
    }

`

const WhyInvestImage = styled.div`

`

const WhyInvestInfo = styled.div`
    margin-top: 5em;
    margin-left: 5em;
    margin-bottom: 8em;

    @media screen and (max-width: 1020px){
      margin: 5em 2em;
    }

    @media screen and (max-width: 768px){
      margin: 5em 1em;
    }
`

const WhyInvestHeader = styled.h2`
    font-size: 3em;
    margin-bottom: 1em;
`

const WhyInvestText = styled.p`
    margin: 1em 0;
`

const WhyInvestList = styled.ul`
    padding: 0 4em;
    line-height: 1.8em;
`

const WhyInvestListItem = styled.li`
    
`