import React from 'react'
import styled from 'styled-components'

const Note = () => {
  return (
    <NoteSection>
      <NoteText>It takes effort to incorporate inbound leads into your sales process and make it a seamless experience.</NoteText>
    </NoteSection>
  )
}

export default Note

const NoteSection = styled.section`
    height: 380px;
    background-color: #2A2C2B;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
`

const NoteText = styled.p`
    text-align: center;
    font-size: 1.6em;
    font-weight: 300;
    line-height: 1.4;
    color: #ffffff;
    width: 40%;

    @media screen and (max-width: 1020px){
      width: 60%;
  }

  @media screen and (max-width: 768px){
      width: 80%;
  }
`