import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Button } from "./Button"

const Services = () => {
  return (
    <ServicesSection>
      <ServicesContainer>
        <ServiceHeading>Services</ServiceHeading>
          <ServicesList>
            <SubService>
              <SubServiceHeading>Web Development</SubServiceHeading>
              <SubServiceText>Get a fully custom solution based on your needs. My specialization is building fast loading sites with SEO and content in mind.</SubServiceText>
              <SubServiceText>Don't want to update the site by yourself? No problem! I can update content as requested.</SubServiceText>
              <Button to="/web-development" primary="true" fweight="true">About Web Development</Button>
            </SubService>
            <SubServiceImage id="first"><StaticImage src="../images/Web_Development.svg" alt="Sketch of me producing web development services" width={300}></StaticImage></SubServiceImage>
            <SubServiceImage id="adjust"><StaticImage src="../images/Web_Design.svg" alt="Sketch of design tools on a table" width={350} style={{ }}></StaticImage></SubServiceImage>
            <SubServiceFloat>
              <SubServiceHeading>Web Design</SubServiceHeading>
              <SubServiceText>As bad design and usability will kill conversions and scare visitors away, having a good design has become hugely important aspect on a modern website.</SubServiceText>
              <SubServiceText>Let me help you show your company in best possible light to the customers so that your message carries through.</SubServiceText>
              <Button to="/web-design" primary="true" fweight="true">About Web Design</Button>
            </SubServiceFloat>
            <SubService>
              <SubServiceHeading>SEO Consultation</SubServiceHeading>
              <SubServiceText>I will give you a roadmap to success by choosing the right keywords to rank for and create a content plan in order to reach your longterm goals.</SubServiceText>
              <SubServiceText>I can also help integrate and plan social media as a part of your business to drive more traffic to your website.</SubServiceText>
              <Button to="/seo-consultation" primary="true" fweight="true">About SEO Services</Button>
            </SubService>
            <SubServiceImage id="fifth"><StaticImage src="../images/SEO_Consultation.svg" alt="Sketch of me consulting clients of SEO" width={300}></StaticImage></SubServiceImage>
          </ServicesList>
      </ServicesContainer>
    </ServicesSection> 
  )
}

export default Services


const ServicesSection = styled.section`
  background-color: #ede6cb;
`

const ServicesContainer = styled.div`
    margin: 0 9.375rem;
    padding: 4rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0;
    }
`


const ServiceHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }

`

const ServicesList = styled.div`
    margin: 4em 5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 4em;
    grid-auto-columns: minmax(400px, auto);

    @media screen and (max-width: 1020px){
    grid-row-gap: 2em;
    }

    @media screen and (max-width: 768px){
    grid-template-columns: 1fr;

    #first{
      grid-row: 1; /* added */
    }
    #fifth{
      grid-row: 5; /* added */
    }
    }

`

const SubService = styled.div`

`

const SubServiceImage = styled.div`
    #adjust{
      margin: 4em 0em;
    }
    @media screen and (max-width: 1020px){
    margin: 0;
    :nth-child(n+2){
    margin-top: 4em;
    }
    }

    @media screen and (max-width: 768px){

    }
`

const SubServiceFloat = styled(SubService)`
    
`

const SubServiceHeading = styled.h3`
    font-weight: 600;
    font-size: 32px;
    color: #2A2C2B;
    margin-bottom: 1.2em;
`

const SubServiceText = styled.p`
  width: 90%;
  margin-bottom: 1.4em;
`