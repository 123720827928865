import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import document from '../images/Document.svg'
import checkmark from '../images/Checkmark.svg'
import compensation from '../images/Compensation.svg'
import codeassets from '../images/CodeAssets.svg'

const DevProcess = () => {
  return (
    <DevProcessSection>
      <DevProcessContainer>
        <DevProcessHeading>Process</DevProcessHeading>
        <DevProcessImage>
            <StaticImage src="../images/Development_Process.png" alt="Development process" width={1200}/>
        </DevProcessImage>
        <ProcessDesc>
            <ProcessIcons>
              <ProcessIcon><img src={document} alt="Document icon" width={24} style={{marginRight: .2 + 'em'}}></img></ProcessIcon>
              <ProcessIconText>Document</ProcessIconText>
              <ProcessIcon><img src={checkmark} alt="Revision checkmark icon" width={32}></img></ProcessIcon>
              <ProcessIconText>Revision step</ProcessIconText>
              <ProcessIcon><img src={compensation} alt="Compensation icon" width={32} style={{marginRight: -.3 + 'em'}}></img></ProcessIcon>
              <ProcessIconText>Compensation</ProcessIconText>
              <ProcessIcon><img src={codeassets} alt="Code Assets icon" width={32} ></img></ProcessIcon>
              <ProcessIconText>Code Assets</ProcessIconText>
            </ProcessIcons>
        </ProcessDesc>
        <ProcessDesc>
          <ProcessTitle>About the Process</ProcessTitle>
          <ProcessText>The process starts by me making assesment of the business needs,  needed features and time estimation for the whole project.  Once we have contract in place, we can start designing. After each <span style={{color: '#0FB680', fontWeight: '500'}}>revision</span> is done the next phase can begin. As a final product you’ll receive <span style={{color: '#FF6600', fontWeight: '500'}}>code assets</span> and all the content deployed on the web.
                        <br></br><br></br>The site maintenance can be done either with a monthly retainer (5 or 10 hours) or on a fixed hourly rate.</ProcessText>
        </ProcessDesc>
        <ProcessDesc>
          <ProcessTitle style={{color: '#079EFF'}}>Documentation</ProcessTitle>
          <ProcessText>As a part of the process, some key documentation is produced to make sure everything is clear before moving on to the next phases of the project.</ProcessText>
        </ProcessDesc>
        <ProcessDesc>
          <ProcessTitle style={{color: '#06B217'}}>Compensation</ProcessTitle>
          <ProcessText>The divided payment structure <span style={{color: '#6B767E', fontWeight: '400'}}>( 33-33-34% )</span> brings crucial benefits to the process by lowering risk for both parties. This ensures that both sides are dedicated to the project and all content required is delivered in a timely manner. </ProcessText>
        </ProcessDesc>

      </DevProcessContainer>
    </DevProcessSection>
  )
}

export default DevProcess

const DevProcessSection = styled.section`

`

const DevProcessContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const DevProcessHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const DevProcessImage = styled.div`
    border: 1px solid #BDC3C7;
    margin: 3em;
    padding: 4em;

    @media screen and (max-width: 1020px){
      margin: 0;
      padding: 2em;
    }

    @media screen and (max-width: 768px){
      padding: 1em;
    }
    
`

const ProcessIcons = styled.div`
    display: flex;
    
    @media screen and (max-width: 1250px){
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 1em;
    }

    @media screen and (max-width: 768px){
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
`

const ProcessIcon = styled.div`

`

const ProcessDesc = styled.div`
    background-color: #ECECEC;
    padding: 1em 4em;
    border-left: .6em solid #BDC3C7;
    margin: 2em 5em;

    @media screen and (max-width: 1020px){
      padding: 1em 2em;
    }

    @media screen and (max-width: 768px){
      margin: 2em 0;
    }
`

const ProcessTitle = styled.h3`
    color: #2A2C2B;
    text-transform: uppercase;
    margin-top: 1.4em;
    margin-bottom: 1em;
`

const ProcessText = styled.p`
    margin-bottom: 2em;
    width: 100%;
    line-height: 1.6;

    @media screen and (max-width: 1250px){
      width: 100%;
    }
    
    @media screen and (max-width: 1020px){
      width: 100%;
    }

    @media screen and (max-width: 768px){
      width: 100%;
    }
`

const ProcessIconText = styled.p`
    margin: .2em 2.4em 0 .4em;
`