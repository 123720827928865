import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const WhatMakesMeSpecial = () => {
  return (
    <WhatMakesMeSpecialContainer>
      <WhatMakesMeSpecialTitle>WHAT MAKES ME SPECIAL</WhatMakesMeSpecialTitle>
      <WhatMakesMeSpecialLayout>
        <WhatMakesMeSpecialTextBox>
            <WhatMakesMeSpecialSubtitle>In this industry there are many service providers that try to fit customers needs in the same generic mold. Although mass production of web services can cut costs and lead to easier maintenance there's a massive missed opportunity in finding competitive advantages on the web.</WhatMakesMeSpecialSubtitle>
            <WhatMakesMeSpecialSubtitle>To battle the boring and the uninspiring I have a fight plan that is more <span style={{fontWeight: 600}}>detailed</span> and likely to get  <span style={{fontWeight: 600}}>results</span>. </WhatMakesMeSpecialSubtitle>
            <WhatMakesMeSpecialSubtitle>I’m also willing to take that extra mile to make sure my clients get a <span style={{fontWeight: 600}}>fully custom solution</span> to their situation that solves their specific challenges.</WhatMakesMeSpecialSubtitle>
        </WhatMakesMeSpecialTextBox>
        <WhatMakesMeSpecialImage>
          <StaticImage src="../images/Developers-viewpoint.png" alt="Me sharing my viewpoint on web development industry" placeholder="#fff" layout="constrained" height={992}/>
        </WhatMakesMeSpecialImage>
      </WhatMakesMeSpecialLayout>
    </WhatMakesMeSpecialContainer>
  )
}

export default WhatMakesMeSpecial


const WhatMakesMeSpecialContainer = styled.div`
    margin: 0 9.375rem;
    padding: 0 calc((100vw - 1440px) / 2);
    color: #2A2C2B;
    text-transform: uppercase;


    @media screen and (max-width: 1020px){
      margin: 0 2rem;
      padding: 0;
    }

    @media screen and (max-width: 768px){
      margin: 0;
      padding: 0;
    }
`

const WhatMakesMeSpecialLayout = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4em;

    @media screen and (max-width: 1020px){
    flex-direction: column-reverse;
    }

`

const WhatMakesMeSpecialTitle = styled.h1`
    margin: 2em 0;
    font-size: 3em;
    font-weight: 800;

    @media screen and (max-width: 768px){
      margin-left: 1rem;
    }
`

const WhatMakesMeSpecialTextBox = styled.div`
    max-width: 42%;
    text-transform: none;
    background-color: white;
    margin-right: -20%;
    padding: 2em 2em 4em 0;
    //position: absolute;
    align-self: center;

    @media screen and (max-width: 1020px){
    max-width: 70%;
    margin: 0 2rem;
    padding: 2em 0;
    }

    @media screen and (max-width: 768px){

    }
`

const WhatMakesMeSpecialSubtitle = styled.h2`
    font-size: 1.2em;
    margin-top: 1em;
    font-weight: 400;
    color: #2A2C2B;
`

const WhatMakesMeSpecialImage = styled.div`
    z-index: -2;

`