import React from 'react'
import styled from 'styled-components'
import trending from "../images/trending_up_black_24dp.svg"
import build from "../images/build_black_24dp.svg"
import brackets from "../images/code_black_24dp.svg"
import colorwheel from "../images/color_lens_black_24dp.svg"

const WhyChooseMe = () => {
  return (
    <WhySection>
      <WhyContainer>
        <WhyHeading>KEY BENEFITS</WhyHeading>
        <ReasonList>
          <ReasonBox>
            <ReasonImage><img src={trending} alt="Arrow trending up" width={50}></img></ReasonImage>
            <ReasonTitle>Results</ReasonTitle>
            <ReasonText>I strive to find the problematic areas of the business, so that you can save time, become more profitable and have less stress.</ReasonText>
          </ReasonBox>
          <ReasonBox style={{borderLeftColor: '#A3BED2'}}>
            <ReasonImage><img src={build} alt="A service wrench" width={32} style={{margin: .5 + 'em'}}></img></ReasonImage>
            <ReasonTitle>Service</ReasonTitle>
            <ReasonText>My services are tailored to fit your needs. I can host your site and keep it updated all year long.</ReasonText>
          </ReasonBox>
          <ReasonBox style={{borderLeftColor: '#F5A0AF'}}>
            <ReasonImage><img src={brackets} alt="Code brackets" width={46}></img></ReasonImage>
            <ReasonTitle>Technology</ReasonTitle>
            <ReasonText>I will build the project with the best tools and technology suitable for the job. All my websites are responsive on all devices and SEO optimized.</ReasonText>
          </ReasonBox>
          <ReasonBox style={{borderLeftColor: '#F8C984'}}>
            <ReasonImage><img src={colorwheel} alt="A color wheel" width={34} style={{margin: .4 + 'em'}}></img></ReasonImage>
            <ReasonTitle>Customization</ReasonTitle>
            <ReasonText>Your site can be customized to look, feel and function in a desired way. Adding new content and pages is a breeze.</ReasonText>
          </ReasonBox>
        </ReasonList>
      </WhyContainer>
    </WhySection>
  )
}

export default WhyChooseMe

const WhySection = styled.section`

`

const WhyContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const WhyHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const ReasonList = styled.div`
    margin: 4em 5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 3em;

    @media screen and (max-width: 1020px){
      
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      grid-template-columns: 1fr;
      margin: 0 1rem;
    }
`

const ReasonBox = styled.div`
    background-color: #ECECEC;
    padding: 1em 2em 4em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-left: 1.4em solid #A1CCA2;
`

const ReasonImage = styled.div`

`

const ReasonTitle = styled.h3`
    font-weight: 500;
    font-size: 1.6em;
    color: #374140;
    margin-bottom: 1.2em;
`

const ReasonText = styled.p`
    color: #374140;
`