import React from 'react'
import styled from 'styled-components'

function GetResults() {
  return (
    <GetResultsSection>
      <GetResultsContainer>
        <GetResultsImage></GetResultsImage>
        <GetResultsInfo>
          <GetResultsHeader>You know how to run a business and succeed</GetResultsHeader>
          <GetResultsText>Getting results in a small scale is fine and dandy, but still many companies struggle leveraging the full potential of the web.</GetResultsText>
          <GetResultsText>The reason is that most companies do not have a solid strategy and are unable to master all the complexities of running an online business.</GetResultsText>
        </GetResultsInfo>
      </GetResultsContainer>
    </GetResultsSection>
  )
}

export default GetResults

const GetResultsSection = styled.section`
    color: #2A2C2B;
    margin-top: 2em;
`

const GetResultsContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    display: flex;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const GetResultsInfo = styled.div`
    margin-left: 2em;
`

const GetResultsImage = styled.div`
    flex: none;
    background-color: #A3BED2;
    width: 200px;
    height: 200px;

    @media screen and (max-width: 1020px){
      width: 100px;
      height: 260px;
    }

    @media screen and (max-width: 768px){
      width: 50px;
      height: 370px;
    }
`

const GetResultsHeader = styled.h2`
    margin-top: .2em;
    margin-bottom: .6em;
    font-size: 2em;
`

const GetResultsText = styled.p`
    padding: .6rem 0;
    font-size: 1.2rem;
`